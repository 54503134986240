"use client";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-scroll";
import {
  FaGlobe,
  FaMobile,
  FaDesktop,
  FaChartLine,
  FaShieldAlt,
} from "react-icons/fa";
import { FaCloudBolt } from "react-icons/fa6";

export default function Services() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const services = [
    {
      name: "Website Development",
      icon: FaGlobe,
      description:
        "Create stunning, responsive websites that engage your audience and drive results. Our web development solutions are tailored to your unique needs.",
      theme: "light",
    },
    {
      name: "Systems Development",
      icon: FaDesktop,
      description:
        "Build robust, scalable systems that grow with your business. From small startups to large enterprises, adapt seamlessly to any scale of project.",
      theme: "dark",
    },
    {
      name: "Digital Marketing",
      icon: FaChartLine,
      description:
        "Drive growth with data-driven marketing strategies. Our comprehensive approach ensures your message reaches the right audience at the right time.",
      theme: "light",
    },
    {
      name: "Mobile & Computer Apps",
      icon: FaMobile,
      description:
        "Develop powerful applications that work seamlessly across all devices. Our solutions prioritize user experience and performance.",
      theme: "dark",
    },
    {
      name: "Cybersecurity",
      icon: FaShieldAlt,
      description:
        "Protect your digital assets with state-of-the-art security solutions. Our comprehensive security services keep your business safe from threats.",
      theme: "light",
    },
    {
      name: "Cloud Computing",
      icon: FaCloudBolt,
      description:
        "Scale your business with flexible, reliable cloud solutions. Enjoy seamless integration, cost-effective infrastructure, and storage that grows with your needs.",
      theme: "dark",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section id="services" ref={ref} className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">Our Services</h2>
          <p className="text-xl text-gray-600">
            Comprehensive tech solutions for your business needs
          </p>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          {services.map((service, index) => (
            <motion.div
              key={service.name}
              variants={cardVariants}
              className={`
                rounded-3xl p-8 h-full transform hover:scale-105 transition-transform duration-300
                ${
                  service.theme === "dark"
                    ? "bg-gray-900 text-white"
                    : "bg-white text-gray-900 shadow-lg"
                }
              `}
            >
              <div className="flex flex-col h-full">
                <div
                  className={`
                  text-5xl mb-6
                  ${
                    service.theme === "dark" ? "text-blue-400" : "text-blue-600"
                  }
                `}
                >
                  <service.icon />
                </div>
                <h3 className="text-2xl font-bold mb-4">{service.name}</h3>
                <p
                  className={`
                  text-lg mb-6 flex-grow
                  ${
                    service.theme === "dark" ? "text-gray-300" : "text-gray-600"
                  }
                `}
                >
                  {service.description}
                </p>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className={`
                    mt-auto text-left text-lg font-semibold flex items-center
                    ${
                      service.theme === "dark"
                        ? "text-blue-400 hover:text-blue-300"
                        : "text-blue-600 hover:text-blue-700"
                    }
                  `}
                >
                  <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    Learn more
                  </Link>
                  <svg
                    className="w-6 h-6 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </motion.button>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
}
