import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-scroll";

export default function Hero() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section ref={ref} className="w-full py-16 md:py-18 bg-white">
      <div className="container mx-auto px-4 max-w-[1200px]">
        <motion.div
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={variants}
          transition={{ duration: 0.8 }}
          className="text-center space-y-8"
        >
          <h1 className="text-5xl md:text-7xl font-bold tracking-tight relative inline-block">
            Innovative Tech Solutions
            <motion.div
              initial={{ width: 0 }}
              animate={inView ? { width: "100%" } : { width: 0 }}
              transition={{ duration: 0.8, delay: 0.5 }}
              className="absolute bottom-1 left-0 h-3 bg-blue-500/20 -z-10"
            />
          </h1>

          <div className="max-w-4xl mx-auto space-y-6">
            <p className="text-xl md:text-2xl text-gray-900 leading-relaxed">
              Transform your business with innovative tech solutions that fuel🔋
              growth. As your trusted partner, we turn your ideas💡 into
              powerful⚡, scalable 📈 digital solutions.
            </p>

            <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
              Designing impactful digital experiences that inspire action,
              delivering tailored solutions that meet your business needs, both
              internally and externally.
            </p>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 1 }}
              className="pt-8"
            >
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-blue-600 text-white font-semibold px-8 py-4 rounded-full text-lg hover:bg-blue-700 transition-colors duration-300"
                >
                  Get Started
                </motion.button>
              </Link>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
