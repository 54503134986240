import { useState } from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../assets/Logo.png";
export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = ["Home", "Services", "About", "Contact"];
  // const navigate = useNavigate();

  return (
    <header className="bg-white top-0 left-0 right-0  pt-4">
      <div className="container mx-auto flex justify-between items-center pl-4 pr-11">
        <img
          src={logo}
          alt="Logo"
          style={{ height: "5em", width: "5em" }}
          className="pt-1"
        />

        <nav className="hidden md:flex space-x-4">
          <Link
            to="home"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="text-gray-600 hover:underline hover:text-blue-600 transition duration-900 text-2xl cursor-pointer"
          >
            Home
          </Link>
          <Link
            to="services"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="text-gray-600 hover:underline hover:text-blue-600 transition duration-900 text-2xl cursor-pointer"
          >
            Services
          </Link>
          <Link
            to="about"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="text-gray-600 hover:underline hover:text-blue-600 transition duration-900 text-2xl cursor-pointer"
          >
            About
          </Link>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="text-gray-600 hover:underline hover:text-blue-600 transition duration-900 text-2xl cursor-pointer"
          >
            Contact
          </Link>
        </nav>
        <div className="md:hidden pr-5">
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
      {isOpen && (
        <motion.div
          className="md:hidden bg-white shadow-md z-10"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
        >
          <nav className="flex flex-col items-center py-4">
            {menuItems.map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className="text-gray-600 hover:text-blue-600 transition duration-300 py-2"
                onClick={() => setIsOpen(false)}
              >
                {item}
              </a>
            ))}
          </nav>
        </motion.div>
      )}
    </header>
  );
}
