
import React from 'react'
import { motion } from 'framer-motion'
import { FaFacebook, FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa'

export default function Footer() {
  const socialLinks = [
    { icon: FaFacebook, url: '#' },
    { icon: FaTwitter, url: '#' },
    { icon: FaLinkedin, url: '#' },
    { icon: FaGithub, url: '#' },
  ]

  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
      <h1 className='text-4xl mb-4'>
        Ready to Soar?
      </h1>
      <p className="text-sm text-gray-400">
        Reach us out at <a href="mailto:hello@nextsolutions.tech">hello@nextsolutions.tech</a>
      </p>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <p>&copy; 2025 Next Solutions. All rights reserved.</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="flex space-x-4 mt-4 md:mt-0"
          >
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-2xl hover:text-blue-400 transition duration-300"
              >
                <link.icon />
              </a>
            ))}
          </motion.div>
        </div>
      </div>
    </footer>
  )
}

