"use client";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-scroll";

export default function About() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section id="about" ref={ref} className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
          className="max-w-3xl mx-auto text-center"
        >
          <h2 className="text-3xl font-bold mb-6">About Us</h2>
          <p className="text-gray-600 mb-8">
            We are a team of passionate tech experts dedicated to providing
            innovative solutions for businesses of all sizes. With years of
            experience in website development, systems development, marketing,
            mobile and computer application development, and cybersecurity, we
            are committed to helping our clients succeed in the digital world.
          </p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-blue-600 text-white font-semibold px-8 py-4 rounded-full text-lg hover:bg-blue-700 transition-colors duration-300"
          >
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              Learn More
            </Link>
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
}
